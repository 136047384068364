import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// export const URL_API = 'http://lory.sababou-gn.com/api';
// export const URL_API = 'http://localhost:8000/api';
export const URL_API = 'https://api.bestcentrale.com/api';
export const stripePromise = loadStripe('pk_test_51Pqxm8P9CIGk8a2Tii6f8HlH9RwfaDnDCwPb3k2JzV6MxnHAClSiREqyxCeA2Wb5zYy2lWqh6l5Z6YVTMiRqEHOZ001YmdsHjH');

export function getDiscountedPrice(product) {
    // Définir la date d'aujourd'hui et réinitialiser l'heure à 00:00:00 pour ne comparer que la date
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Convertir les dates d'offre en objets Date et réinitialiser l'heure pour ne comparer que la date
    const offerDateDebut = new Date(product.offer_date_debut);
    const offerDateFin = new Date(product.offer_date_fin);
    offerDateDebut.setHours(0, 0, 0, 0);
    offerDateFin.setHours(0, 0, 0, 0);

    // Vérifier si l'offre est active aujourd'hui
    if (product.offer_id && offerDateDebut <= today && offerDateFin >= today) {
        return formatPrice(product.delPrice);
    }

    // Retourner null si les conditions ne sont pas remplies
    return null;
}

export function formatPrice(amount) {
    // Crée un formateur pour le format monétaire en Guinée
    const formatter = new Intl.NumberFormat('fr-GN', {
        style: 'currency',
        currency: 'GNF',
        minimumFractionDigits: 0, // Pas de décimales pour le Franc guinéen
        maximumFractionDigits: 0,
    });

    return formatter.format(amount);
}

export function truncateDescription(description, maxLength = 100) {
    if (description.length <= maxLength) {
        return description;
    }
    return description.substring(0, maxLength) + '...';
}

export const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length === 9) {
        return `+224 ${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
    }
    return phoneNumber;
};

export const Pagination = ({ totalPages, paginate, currentPage }) => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = () => {
        const pages = [];
        if (currentPage > 3) {
            pages.push(
                <li key={1} className={`${currentPage === 1 ? 'active' : ''}`}>
                    <button onClick={() => paginate(1)}>1</button>
                </li>
            );
            if (currentPage > 4) {
                pages.push(<li key="dots-start">...</li>);
            }
        }
        for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, totalPages); i++) {
            pages.push(
                <li key={i} className={`${currentPage === i ? 'active' : ''}`}>
                    <button onClick={() => paginate(i)}>{i}</button>
                </li>
            );
        }
        if (currentPage < totalPages - 2) {
            if (currentPage < totalPages - 3) {
                pages.push(<li key="dots-end">...</li>);
            }
            pages.push(
                <li key={totalPages} className={`${currentPage === totalPages ? 'active' : ''}`}>
                    <button onClick={() => paginate(totalPages)}>{totalPages}</button>
                </li>
            );
        }
        return pages;
    };

    return (
        <div className="pagination-wrapper pagination-wrapper-left">
            <ul className="pg-pagination">
                {
                    currentPage === 1 ? (
                        <li>
                            <button aria-label="Previous" disabled>
                                <i className="fi ti-angle-left"></i>
                            </button>
                        </li>
                    ) : (
                        <li>
                            <button onClick={() => paginate(currentPage - 1)} aria-label="Previous">
                                <i className="fi ti-angle-left"></i>
                            </button>
                        </li>
                    )
                }

                {renderPageNumbers()}

                {
                    currentPage === totalPages ? (
                        <li>
                            <button aria-label="Next" disabled>
                                <i className="fi ti-angle-right"></i>
                            </button>
                        </li>
                    ) : (
                        <li>
                            <button onClick={() => paginate(currentPage + 1)} aria-label="Next">
                                <i className="fi ti-angle-right"></i>
                            </button>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

export const formatDate = (date) => {
    if (!date) {
        return ''; // Retourne une chaîne vide si la date est undefined
    }
    return new Date(date).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};


const usePreviousPage = () => {
    const location = useLocation();

    useEffect(() => {
        localStorage.setItem('previousPage', location.pathname);

        // Sauvegarde l'URL de la page précédente dans le localStorage
        // const previousPage = localStorage.getItem('previousPage');
        // if (previousPage && previousPage !== location.pathname) {
        //     localStorage.setItem('previousPage', location.pathname);
        // }
    }, [location.pathname]);
};
export default usePreviousPage;

export const logout = async () => {
    try {
        const token = localStorage.getItem('login');

        if (!token) throw new Error('No auth token found');

        await axios.post(`${URL_API}/logout`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Supprimez le token du localStorage
        localStorage.removeItem('login');
        localStorage.removeItem('user');

        window.location.href = localStorage.getItem('previousPage');

    } catch (error) {
        toast.error('Erreur lors de la déconnexion');
    }
};

export const useStripePay = () => {
    const stripe = useStripe();
    const elements = useElements();

    const checkStripePay = async () => {
        if (!stripe || !elements) {
            return;
        }

        const response = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
            },
            redirect: 'if_required'
        });

        if (response.error && (response.error.type === "card_error" || response.error.type === "validation_error")) {
            return response.error.message;
        } else if (response.paymentIntent?.status === 'succeeded') {
            return response.paymentIntent.id;
        }
    };

    return checkStripePay;
};
