import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import abimg from '../../images/abou2.jpg';
import axios from 'axios';
import { truncateDescription, URL_API } from '../../utils/helpers';
import { toast } from 'react-toastify';

const About = () => {
    const ClickHandler = () => {
        localStorage.setItem('previousPage', '/about');
        window.scrollTo(10, 0);
    };

    const page = localStorage.getItem('previousPage');
    const [about, setAbout] = useState(null);

    const getData = () => {
        axios.get(`${URL_API}/getAbout`)
            .then(response => {
                setAbout(response.data.aboutData);
            })
            .catch(error => {
                toast.error("Oups !! Erreur de récupération des données");
            });
    }

    useEffect(() => {
        getData();
    }, [about]);

    if (!about) {
        return <div>Chargement des données...</div>;
    }

    return (
        <section className="about-section section-padding p-t-0">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-5 col-12">
                        <div className="video-area">
                            <img src={about?.images || abimg} alt="About Image" />
                            {/* <div className="video-holder">
                                <VideoModal />
                            </div> */}
                        </div>
                    </div>
                    <div className="col col-lg-7 col-12 align-items-center">
                        <div className="about-area">
                            <div className="about-wrap" style={{ textAlign: 'justify' }}>
                                <div className="about-title">
                                    <small>À propos de notre entreprise </small>
                                    <h2>{about.title}</h2>
                                </div>
                                {page === '/about' ? (
                                    <div dangerouslySetInnerHTML={{ __html: about.description }}></div>
                                ) : (
                                    <>
                                        <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: truncateDescription(about?.description ?? '', 600) }}></p>
                                        <Link onClick={ClickHandler} to="/about" className="btn theme-btn">
                                            En savoir plus<i className="fa fa-angle-double-right"></i>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
