import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo_blanc.png'


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className="tp-site-footer">
            <div className="tp-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={ClickHandler} to="/"><img src={Logo} alt="ft-logo" /></Link>
                                </div>
                                <p>Chez Best Centrale d'Achat SARL, nous valorisons la communication ouverte et l'accessibilité.</p>
                                <ul>
                                <li>
                                    <a
                                        onClick={ClickHandler}
                                        href="https://www.facebook.com/profile.php?id=61567810256925&mibextid=ZbWKwL"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="ti-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={ClickHandler}
                                        href="https://www.linkedin.com/company/best-centrale-d-achat-bca-sarl/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="ti-linkedin"></i>
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget tp-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-pin"></i>Conakry, Matoto, Gbessia Kondebougny
                                        </li>
                                        <li><i className="fi flaticon-call"></i>+224 620 451 485</li>
                                        <li><i className="fi flaticon-envelope"></i>contact@bestcentrale.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>My Account</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/shop">Boutique</Link></li>
                                    <li><Link onClick={ClickHandler} to="/ressources">Ressources</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <p>You will be notified when somthing new will be appear.</p>
                                <form>
                                    <div className="input-1">
                                        <input type="email" className="form-control" placeholder="Email Address *" required />
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit"><i className="ti-email"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="tp-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> Copyright &copy; 2024 B.C.A by <Link onClick={ClickHandler} to="/">ElceTo Holding</Link>.
                                All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-shape1">
                <i className="fi flaticon-shopping-cart"></i>
            </div>
            <div className="footer-shape2">
                <i className="fi flaticon-bag"></i>
            </div>
        </footer>
    )
}

export default Footer;